import React, { useEffect, useState } from 'react';

const SectionCard = ({ isVisible, text, text2, text3, text4, text5, text6, highlight, icon, index }) => {
    const [isCardVisible, setIsCardVisible] = useState(false);

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                setIsCardVisible(true);
            }, 200 * (index + 1))
        }
    }, [isVisible]);

    return (
        <div className={`section-card ${isCardVisible ? 'visible' : ''}`}>
            <h4 className={'section-card-title'}>{highlight}</h4>
            <p className={'section-card-description'}>{text}</p>
            {
                !!text2 &&
                <>
                    <p className={'section-card-description'}>{text}</p>
                    <p className={'section-card-description'}>{text2}</p>
                    <p className={'section-card-description'}>{text3}</p>
                    <p className={'section-card-description'}>{text4}</p>
                    <p className={'section-card-description'}>{text5}</p>
                    <p className={'section-card-description'}>{text6}</p>
                </>
            }
            {
                !!icon &&
                <div className={'section-card-icon-container'}>
                    <i className={icon}/>
                </div>
            }
        </div>
    );
}

export default SectionCard;
